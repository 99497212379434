import { Fragment, useEffect, useState } from "react";
import { Box, Divider, IconButton, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";

import {
  ApprovalRequestDisciplineStatus,
  DisciplineTrackingComment,
  DisciplineTrackingCommentNote,
  DisciplineTrackingCommentStatus
} from "@/interfaces";
import { formatLocalDate } from "@/utils";
import { useARContext, useAuthorization, useDisciplineTabContext } from "@/context";
import { EditTrackingCommentNote } from "./EditTrackingCommentNote";

interface TrackingCommentNoteProps {
  trackingCommentStatus: DisciplineTrackingCommentStatus;
  trackingCommentNote: DisciplineTrackingCommentNote;
  onEdit: (trackingCommentNote: DisciplineTrackingCommentNote) => void;
  onDelete: (trackingCommentNote: DisciplineTrackingCommentNote) => void;
}

function TrackingCommentNote({
  trackingCommentStatus,
  trackingCommentNote,
  onEdit,
  onDelete
}: TrackingCommentNoteProps) {
  const theme = useTheme();
  const { userId } = useAuthorization();
  const { userIsSMEOfDisciplineOrAdmin } = useARContext();
  const { currentARDiscipline } = useDisciplineTabContext();
  const { created, note, author, authorId } = trackingCommentNote;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const kebabMenuDisabled =
    trackingCommentStatus === DisciplineTrackingCommentStatus.Completed ||
    userId !== authorId ||
    !userIsSMEOfDisciplineOrAdmin ||
    currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised;

  return (
    <Stack direction="row" minHeight="2rem">
      <Box display="flex" flex="1" justifyContent="flex-start" alignItems="center">
        <Typography fontSize={theme.typography.body2.fontSize}>{formatLocalDate(created)}</Typography>
      </Box>
      <Box display="flex" flex="6" justifyContent="flex-start" alignItems="center">
        <Typography fontSize={theme.typography.body2.fontSize}>{note}</Typography>
      </Box>
      <Box display="flex" flex="3" justifyContent="flex-end" alignItems="center">
        <Typography fontSize={theme.typography.body2.fontSize}>{author}</Typography>
      </Box>
      <IconButton
        onClick={(event) => {
          setMenuAnchor(event.currentTarget);
        }}
        disabled={kebabMenuDisabled}
        sx={{ ml: "1rem" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => {
          setMenuAnchor(null);
        }}
        aria-disabled={kebabMenuDisabled}
      >
        <MenuItem
          onClick={() => {
            onEdit(trackingCommentNote);
            setMenuAnchor(null);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete(trackingCommentNote);
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Stack>
  );
}

interface TrackingCommentNotesProps {
  trackingComment: DisciplineTrackingComment;
  notes: DisciplineTrackingCommentNote[];
  updateTrackingCommentNotes: (notes: DisciplineTrackingCommentNote[]) => void;
}

export function TrackingCommentNotes({
  trackingComment,
  notes,
  updateTrackingCommentNotes
}: TrackingCommentNotesProps) {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [modifiedNote, setModifiedNote] = useState<DisciplineTrackingCommentNote | null>(null);
  const [indexedNotes, setIndexedNotes] = useState<Record<string, DisciplineTrackingCommentNote>>({});

  useEffect(() => {
    const indexedNotes: Record<string, DisciplineTrackingCommentNote> = {};

    notes.forEach((note) => {
      if (trackingComment.isLegacy) {
        indexedNotes[note.id] = note;
      } else {
        indexedNotes[note.created.toString()] = note;
      }
    });

    setIndexedNotes(indexedNotes);
  }, [notes]);

  const updateTrackingCommentNote = (updatedTrackingCommentNote: DisciplineTrackingCommentNote) => {
    const updatedNotes = {
      ...indexedNotes,
      [updatedTrackingCommentNote.created.toString()]: updatedTrackingCommentNote
    };

    setIndexedNotes(updatedNotes);
    updateTrackingCommentNotes(Object.values(updatedNotes));
  };

  const deleteTrackingCommentNote = (trackingCommentNoteCreatedDate: Date) => {
    const updatedNotes = {
      ...indexedNotes
    };
    delete updatedNotes[trackingCommentNoteCreatedDate.toString()];

    setIndexedNotes(updatedNotes);
    updateTrackingCommentNotes(Object.values(updatedNotes));
  };

  return (
    <Stack justifyContent="center">
      {Object.values(indexedNotes).map((trackingCommentNote, index, array) => (
        <Fragment key={`${trackingCommentNote.id}-${index}`}>
          <TrackingCommentNote
            trackingCommentStatus={trackingComment.status}
            trackingCommentNote={trackingCommentNote}
            onEdit={(trackingCommentNote) => {
              setModifiedNote(trackingCommentNote);
              setShowEditDialog(true);
            }}
            onDelete={() => {
              deleteTrackingCommentNote(trackingCommentNote.created);
            }}
          />
          {array.length - 1 === index ? null : <Divider sx={{ m: 0 }} />}
        </Fragment>
      ))}
      {modifiedNote !== null ? (
        <EditTrackingCommentNote
          open={showEditDialog}
          setOpen={setShowEditDialog}
          trackingComment={trackingComment.comment}
          trackingCommentNote={modifiedNote}
          updateTrackingCommentNote={updateTrackingCommentNote}
        />
      ) : null}
    </Stack>
  );
}

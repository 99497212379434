import { atom } from "jotai";
import { QAAnswerItem } from "@/interfaces";
import { atomWithReset } from "jotai/utils";

export const approvalRequestQuestionAnswerListAtom = atom<QAAnswerItem[]>([]);

export const uploadInProgressAtom = atom<boolean>(false);

export const isTrackingCommentUpdatableAtom = atomWithReset<boolean>(false);
export const isAuthorisationCommentUpdatableAtom = atom<boolean>(false);

import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";

import { EmptyTrackingComments } from "./EmptyTrackingComments";
import { useState } from "react";
import { useARContext, useDisciplineTabContext } from "@/context";
import { ExistingTrackingComments } from "./ExistingTrackingComments";
import {
  ApprovalRequestDisciplineStatus,
  ApprovalRequestStatus,
  DisciplineTrackingComment,
  DisciplineTrackingCommentStatus,
  ReferenceDataTrackingComment
} from "@/interfaces";
import { useReferenceTrackingComment } from "@/hooks";

export function TrackingComments() {
  const {
    approvalRequestDisciplineId,
    trackingComments,
    currentARDiscipline,
    updateTrackingComments: setTrackingComments
  } = useDisciplineTabContext();
  const { approvalRequest, userIsSMEOfDisciplineOrAdmin } = useARContext();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuIsOpen = Boolean(menuAnchor);

  const { data: referenceTrackingComments } = useReferenceTrackingComment(currentARDiscipline.disciplineId);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const addTrackingCommentHandler = (referenceTrackingComment: ReferenceDataTrackingComment) => {
    const selectedTrackingComment: DisciplineTrackingComment = {
      id: crypto.randomUUID(),
      trackingCommentId: referenceTrackingComment.id,
      comment: referenceTrackingComment.comment,
      displayOrder: referenceTrackingComment.displayOrder,
      isComplete: false,
      isInternal: referenceTrackingComment.isInternal,
      isLegacy: referenceTrackingComment.isLegacy,
      isRemoved: false,
      status: DisciplineTrackingCommentStatus.InProgress,
      expectedAuthorisationDate: undefined,
      notes: [],
      approvalRequestDisciplineId: approvalRequestDisciplineId,
      disciplineTrackingCommentDependencies: []
    };
    setTrackingComments([selectedTrackingComment, ...trackingComments], true);
  };

  const noTrackingCommentsSelected = trackingComments.length === 0;

  return (
    <Stack>
      <Stack direction="row" height="3rem" alignItems="center" columnGap={1}>
        <Typography sx={{ fontSize: "1.25rem" }}>Tracking Comments</Typography>
        <Button
          onClick={(event) => setMenuAnchor(event.currentTarget)}
          disabled={
            currentARDiscipline.status === ApprovalRequestDisciplineStatus.Authorised ||
            approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed ||
            !userIsSMEOfDisciplineOrAdmin
          }
        >
          Add
        </Button>
        <Menu
          id="tracking-comment-menu"
          anchorEl={menuAnchor}
          open={menuIsOpen}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
          aria-disabled={!userIsSMEOfDisciplineOrAdmin}
        >
          {referenceTrackingComments.map((comment) => (
            <MenuItem
              value={comment.id}
              onClick={() => {
                addTrackingCommentHandler(comment);
                handleCloseMenu();
              }}
            >
              {comment.comment}
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      {noTrackingCommentsSelected ? <EmptyTrackingComments /> : <ExistingTrackingComments />}
    </Stack>
  );
}

import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate, useParams } from "react-router";
import { isEqual } from "lodash";

import { APPROVAL_REQUEST_PATH, PATH } from "@/constants";
import {
  ARDiscipline,
  DisciplineAuthorisationComment,
  DisciplineTrackingComment,
  DisciplineTrackingCommentError
} from "@/interfaces";
import { useDisciplineAuthorisationComments, useDisciplineTrackingComments } from "@/hooks";
import { Loading } from "@/components";
import { useARContext } from "./ApprovalRequestContextProvider";
import { useSetAtom } from "jotai";
import { isAuthorisationCommentUpdatableAtom, isTrackingCommentUpdatableAtom } from "@/stores/approvalRequestDataStore";

interface DisciplineTabContextType {
  approvalRequestDisciplineId: string;
  currentARDiscipline: ARDiscipline;
  trackingComments: DisciplineTrackingComment[];
  updateTrackingComments: (trackingComments: DisciplineTrackingComment[], isDirty?: boolean) => void;
  trackingCommentErrors: Record<DisciplineTrackingCommentError["trackingCommentId"], DisciplineTrackingCommentError>;
  updateTrackingCommentErrors: Dispatch<SetStateAction<DisciplineTabContextType["trackingCommentErrors"]>>;
  disciplineAuthorisationComments: DisciplineAuthorisationComment[];
  updateDisciplineAuthorisationComments: (
    authorisationComments: DisciplineAuthorisationComment[],
    isDirty?: boolean
  ) => void;
  updateDisciplineAuthorisationCommentId: Dispatch<SetStateAction<string>>;
  isTabDirty: boolean;
}

const DisciplineTabContext = createContext<DisciplineTabContextType | undefined>(undefined);

export const useDisciplineTabContext = () => {
  const context = useContext(DisciplineTabContext);

  if (context === undefined) {
    throw new Error("Cannot use 'DisciplineTabContext' without a 'DisciplineTabContextProvider'.");
  }

  return context;
};

export function DisciplineTabContextProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { approvalRequestDisciplineId } = useParams();
  const {
    allTrackingComments,
    allTrackingCommentErrors,
    updateAllTrackingComments,
    allAuthorisationComments,
    updateAllAuthorisationComments,
    approvalRequestDisciplines,
    dirtyTabs
  } = useARContext();

  const [trackingComments, setTrackingComments] = useState<DisciplineTrackingComment[]>([]);
  const [trackingCommentErrors, setTrackingCommentErrors] = useState<DisciplineTabContextType["trackingCommentErrors"]>(
    {}
  );

  const setIsTrackingCommentUpdatable = useSetAtom(isTrackingCommentUpdatableAtom);
  const setIsAuthorisationCommentUpdatable = useSetAtom(isAuthorisationCommentUpdatableAtom);
  const [authorisationComments, setAuthorisationComments] = useState<DisciplineAuthorisationComment[]>([]);
  const [_, setDisciplineAuthorisationCommentId] = useState<string>("");

  const { data: fetchedTrackingComments, isSuccess: dtcSuccess } =
    useDisciplineTrackingComments(approvalRequestDisciplineId);

  const { data: fetchedAuthorisationComments, isSuccess: dacSuccess } =
    useDisciplineAuthorisationComments(approvalRequestDisciplineId);

  useEffect(() => {
    if (!approvalRequestDisciplineId) {
      return;
    }

    if (dtcSuccess) {
      setTrackingComments(fetchedTrackingComments);
      updateAllTrackingComments({ [approvalRequestDisciplineId]: fetchedTrackingComments }, false);
    }
  }, [dtcSuccess, fetchedTrackingComments, approvalRequestDisciplineId]);

  useEffect(() => {
    if (!approvalRequestDisciplineId) {
      return;
    }

    if (dacSuccess) {
      setAuthorisationComments(fetchedAuthorisationComments);
      updateAllAuthorisationComments({ [approvalRequestDisciplineId]: fetchedAuthorisationComments }, false);
    }
  }, [dacSuccess, fetchedAuthorisationComments, approvalRequestDisciplineId]);

  useEffect(() => {
    if (!approvalRequestDisciplineId) {
      return;
    }

    if (approvalRequestDisciplineId in allTrackingCommentErrors) {
      const indexedTrackingCommentErrors: typeof trackingCommentErrors = {};

      allTrackingCommentErrors[approvalRequestDisciplineId]?.forEach(
        (error) => (indexedTrackingCommentErrors[error.trackingCommentId] = error)
      );

      setTrackingCommentErrors(indexedTrackingCommentErrors);
    }
  }, [allTrackingCommentErrors, approvalRequestDisciplineId]);

  useEffect(() => {
    if (!approvalRequestDisciplineId) {
      return;
    }
    setIsTrackingCommentUpdatable(!isEqual(trackingComments, fetchedTrackingComments));
    setIsAuthorisationCommentUpdatable(!isEqual(authorisationComments, fetchedAuthorisationComments));
  }, [
    approvalRequestDisciplineId,
    trackingComments,
    fetchedTrackingComments,
    authorisationComments,
    fetchedAuthorisationComments,
    setIsTrackingCommentUpdatable,
    setIsAuthorisationCommentUpdatable
  ]);

  if (!approvalRequestDisciplineId) {
    console.error("Unable to find `approvalRequestDisciplineId` in URL path params.");
    navigate(PATH.MY_REQUESTS);
    return null;
  }

  if ([dtcSuccess, dacSuccess].some((successful) => !successful)) {
    return <Loading />;
  }

  const currentARDiscipline = approvalRequestDisciplines.find(
    (discipline) => discipline.id === approvalRequestDisciplineId
  );

  if (!currentARDiscipline) {
    console.error("Unable to find `approvalRequestDisciplineId` in list of approval request disciplines");
    navigate(APPROVAL_REQUEST_PATH.DETAILS);
    return null;
  }

  const isTabDirty = dirtyTabs[approvalRequestDisciplineId] ?? false;

  const updateTrackingComments = (trackingComments: DisciplineTrackingComment[], isDirty?: boolean) => {
    setTrackingComments(trackingComments);
    const resetDirty = isEqual(trackingComments, fetchedTrackingComments);
    updateAllTrackingComments({ [approvalRequestDisciplineId]: trackingComments }, isDirty ?? !resetDirty);
  };

  const updateAuthorisationComments = (authorisationComments: DisciplineAuthorisationComment[], isDirty?: boolean) => {
    setAuthorisationComments(authorisationComments);
    const resetDirty = isEqual(authorisationComments, fetchedAuthorisationComments);
    updateAllAuthorisationComments({ [approvalRequestDisciplineId]: authorisationComments }, isDirty ?? !resetDirty);
  };

  const contextValue: DisciplineTabContextType = {
    approvalRequestDisciplineId,
    currentARDiscipline,
    trackingComments: allTrackingComments[approvalRequestDisciplineId] ?? trackingComments,
    updateTrackingComments,
    trackingCommentErrors,
    updateTrackingCommentErrors: setTrackingCommentErrors,
    disciplineAuthorisationComments: allAuthorisationComments[approvalRequestDisciplineId] ?? authorisationComments,
    updateDisciplineAuthorisationComments: updateAuthorisationComments,
    updateDisciplineAuthorisationCommentId: setDisciplineAuthorisationCommentId,
    isTabDirty
  };

  return (
    <Suspense fallback={<Loading prefix="Discipline" />}>
      <DisciplineTabContext.Provider value={contextValue}>{children}</DisciplineTabContext.Provider>
    </Suspense>
  );
}

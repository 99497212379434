import { useState, useEffect } from "react";
import { Stack } from "@mui/material";

import { useDisciplineTabContext } from "@/context";
import { DisciplineTrackingComment } from "@/interfaces";
import { TrackingComment } from "./TrackingComment";
import { useDisciplineTrackingComments } from "@/hooks/api/DisciplineTrackingCommentHooks";

export const ExistingTrackingComments = () => {
  const { trackingComments, updateTrackingComments, approvalRequestDisciplineId } = useDisciplineTabContext();
  const [trackingCommentsById, setTrackingCommentsById] = useState<Record<string, DisciplineTrackingComment>>({});

  const { data: fetchedTrackingComments } = useDisciplineTrackingComments(approvalRequestDisciplineId);

  const fetchedTrackingCommentsIds = fetchedTrackingComments.map(({ id }) => id);

  useEffect(() => {
    const indexedTrackingComments: Record<string, DisciplineTrackingComment> = {};

    trackingComments.forEach((comment) => (indexedTrackingComments[comment.id] = comment));

    setTrackingCommentsById(indexedTrackingComments);
  }, [trackingComments]);

  const deleteTrackingComment = (id: string) => {
    if (fetchedTrackingCommentsIds.includes(id)) {
      const updatedTrackingComments = {
        ...trackingCommentsById,
        [id]: {
          ...trackingCommentsById[id],
          isRemoved: true
        }
      };

      setTrackingCommentsById(updatedTrackingComments);
      updateTrackingComments(Object.values(updatedTrackingComments));
      return;
    }

    const updateTCObjects = { ...trackingCommentsById };
    delete updateTCObjects[id];
    setTrackingCommentsById(updateTCObjects);
    updateTrackingComments(Object.values(updateTCObjects));
  };

  const updateTrackingComment = (updatedComment: DisciplineTrackingComment, isDirty?: boolean) => {
    const updatedTrackingCommentsById = {
      ...trackingCommentsById,
      [updatedComment.id]: updatedComment
    };

    setTrackingCommentsById(updatedTrackingCommentsById);
    updateTrackingComments(Object.values(updatedTrackingCommentsById), isDirty);
  };

  return (
    <Stack spacing="1.5rem">
      {Object.values(trackingCommentsById).map((trackingComment, index) => {
        return !trackingComment.isRemoved ? (
          <TrackingComment
            key={`${trackingComment.trackingCommentId}-${index}`}
            disciplineTrackingComment={trackingComment}
            updateTrackingComment={updateTrackingComment}
            deleteTrackingComment={deleteTrackingComment}
          />
        ) : null;
      })}
    </Stack>
  );
};

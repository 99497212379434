//Must be written in lowercase such that correct validation occurs.
export const ACCEPTED_FILE_EXTENSIONS: string[] = [
  ".jpg",
  ".jpeg",
  ".png",
  ".zip",
  ".rar",
  ".7z",
  ".dbf",
  ".shp",
  ".shx",
  ".prj",
  ".sbn",
  ".sbx",
  ".fbn",
  ".fbx",
  ".ain",
  ".aih",
  ".atx",
  ".ixs",
  ".mxs",
  ".prj",
  ".xml",
  ".cpg",
  ".doc",
  ".docm",
  ".dot",
  ".dotm",
  ".dotx",
  ".htm",
  ".docx",
  ".xlsx",
  ".xlsm",
  ".xltx",
  ".xltm",
  ".pdf",
  ".kmz",
  ".dxf"
];

export const ACCEPTED_ZIPFILE_TYPES: string[] = ["application/x-zip-compressed", "application/zip"];

export const ACCEPTED_COMPULSORY_SHAPE_FILE_EXTENSION: string[] = [".prj", ".shp", ".shx", ".dbf"];

export const ACCEPTED_SHAPE_FILE_EXTENSIONS: string[] = [
  ".zip",
  ".dbf",
  ".shp",
  ".shx",
  ".prj",
  ".sbn",
  ".sbx",
  ".fbn",
  ".fbx",
  ".ain",
  ".aih",
  ".atx",
  ".ixs",
  ".mxs",
  ".prj",
  ".xml",
  ".qix",
  ".cpg"
];

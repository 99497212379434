import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useNavigate, useParams } from "react-router";
import { useAtomValue, useSetAtom } from "jotai";
import { FormProvider, useForm } from "react-hook-form";

import { AR_UNEXPECTED_ERROR_BANNER, DISCIPLINE_MESSAGE, PATH } from "@/constants";
import {
  AlertBannerConfig,
  APIException,
  ApprovalRequestBase,
  ApprovalRequestType,
  ARDiscipline,
  ARFormValues,
  ClearingAllocation,
  DisciplineAuthorisationComment,
  DisciplineTrackingComment,
  DisciplineTrackingCommentError,
  AlertBannerConfig,
  UpdateApprovalRequest,
  APIException
} from "@/interfaces";
import { convertFromUTC } from "@/utils";
import { isAuthorisationCommentUpdatableAtom, isTrackingCommentUpdatableAtom, snackBarAtom } from "@/stores";
import {
  useApprovalRequestById,
  useApprovalRequestTypes,
  useARDisciplines,
  useDeleteApprovalRequest,
  useUpdateApprovalRequest,
  useUpdateDisciplineComments
} from "@/hooks";
import { Loading } from "@/components";
import { useAuthorization } from "./AuthorizationProvider";
import { trackingCommentsSchema } from "@/validations";
import { ValidationError } from "yup";
import { useAuthoriseDiscipline } from "@/hooks/api/DisciplineCommentsHooks";
import { useGetApprovalRequestMap } from "@/hooks/api/ApprovalRequestHooks";

export interface ApprovalRequestContextType {
  approvalRequestId: string;
  approvalRequestDisciplineId?: string;
  approvalRequest: ApprovalRequestBase;
  approvalRequestType: ApprovalRequestType;
  isARDirty: boolean;
  saveApprovalRequest: (
    update: UpdateApprovalRequest,
    onSave?: (updatedAR: ApprovalRequestBase) => void,
    onError?: (error: APIException) => void
  ) => void;
  deleteApprovalRequest: (onError?: () => void) => void;
  alertBanners: AlertBannerConfig[];
  appendAlertBanner: (alertBannerConfig: AlertBannerConfig) => void;
  removeAlertBanner: (ids: string[]) => void;
  resetAlertBanner: (exemptions: AlertBannerConfig["id"][]) => void;
  dirtyTabs: Record<string, boolean>;
  ownedTabs: string[];
  approvalRequestDisciplines: ARDiscipline[];
  allTrackingComments: Record<ARDiscipline["id"], DisciplineTrackingComment[]>;
  updateAllTrackingComments: (
    updatedTabs: ApprovalRequestContextType["allTrackingComments"],
    isDirty?: boolean
  ) => void;
  allTrackingCommentErrors: Record<ARDiscipline["id"], DisciplineTrackingCommentError[]>;
  updateAllTrackingCommentErrors: Dispatch<SetStateAction<ApprovalRequestContextType["allTrackingCommentErrors"]>>;
  allAuthorisationComments: Record<ARDiscipline["id"], DisciplineAuthorisationComment[]>;
  updateAllAuthorisationComments: (
    updatedTabs: ApprovalRequestContextType["allAuthorisationComments"],
    isDirty?: boolean
  ) => void;
  resetDisciplineTab: (approvalRequestDisciplineId: string) => void;
  attachmentCountIndicator: number;
  setAttachmentCountIndicator: Dispatch<SetStateAction<number>>;
  userIsSMEOfDiscipline: boolean;
  userIsSMEOfDisciplineOrAdmin: boolean;
  saveDisciplineTabs: (onSuccess?: () => void, onFail?: () => void) => void;
  authoriseDisciplineHandler: () => void;
  userIsSMEOfAr: boolean;
}

export const ApprovalRequestContext = createContext<ApprovalRequestContextType | undefined>(undefined);

export const useARContext = () => {
  const context = useContext(ApprovalRequestContext);

  if (context === undefined) {
    throw new Error("Cannot use 'ApprovalRequestContext' without an 'ApprovalRequestContextProvider'.");
  }

  return context;
};

export function ApprovalRequestContextProvider({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const { approvalRequestId, approvalRequestDisciplineId } = useParams();
  const { userId, isAdmin, isSME } = useAuthorization();

  const [dirtyTabs, setDirtyTabs] = useState<Record<string, boolean>>({});
  const [alertBanners, setAlertBanners] = useState<AlertBannerConfig[]>([]);
  const [allTrackingComments, setAllTrackingComments] = useState<ApprovalRequestContextType["allTrackingComments"]>({});
  const [allTrackingCommentErrors, setAllTrackingCommentErrors] = useState<
    ApprovalRequestContextType["allTrackingCommentErrors"]
  >({});
  const [allAuthorisationComments, setAllAuthorisationComments] = useState<
    ApprovalRequestContextType["allAuthorisationComments"]
  >({});
  const [attachmentCountIndicator, setAttachmentCountIndicator] = useState<number>(0);
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false);

  const isTrackingCommentUpdatable = useAtomValue(isTrackingCommentUpdatableAtom);
  const isAuthorisationCommentUpdatable = useAtomValue(isAuthorisationCommentUpdatableAtom);
  const setSnackBar = useSetAtom(snackBarAtom);

  const navigateToHomePage = () => navigate(PATH.BASE, { replace: true });

  const { data: approvalRequest, isFetched: arFetched } = useApprovalRequestById(approvalRequestId, navigateToHomePage);
  const { data: approvalRequestDisciplines, isFetched: arDisciplinesFetched } = useARDisciplines(approvalRequestId);
  const { data: approvalRequestTypes, isFetched: arTypesFetched } = useApprovalRequestTypes({ isEnabled: true });
  const { data: arMap, isFetched: arMapFetched } = useGetApprovalRequestMap(approvalRequestId);

  const { mutate: updateApprovalRequestHook } = useUpdateApprovalRequest(approvalRequestId!);
  const { mutate: deleteApprovalRequestHook } = useDeleteApprovalRequest();

  const defaultValues: Partial<ARFormValues> = useMemo(
    () => ({
      title: approvalRequest?.title,
      hubId: approvalRequest?.hubId,
      siteId: approvalRequest?.siteId,
      businessUnitId: approvalRequest?.businessUnitId,
      projectId: approvalRequest?.projectId,
      costCode: approvalRequest?.costCode ?? "",
      requiredByDate: approvalRequest?.requiredByDate ? convertFromUTC(approvalRequest.requiredByDate).toDate() : null,
      description: approvalRequest?.description,
      allQuestionsAnswered: false,
      mapUploaded: arMapFetched && !!arMap && arMap.id !== undefined,
      approvalRequestAnswers: [],
      approvalRequestWorkCategories: [],
      extendBy: approvalRequest?.extendBy
    }),
    [approvalRequest, arMap, arMapFetched]
  );

  const formMethods = useForm<ARFormValues>({
    defaultValues
  });

  const {
    formState: { isDirty: isARDetailsDirty },
    reset
  } = formMethods;

  useEffect(() => {
    if (arFetched) {
      reset({ ...defaultValues }, { keepDirtyValues: true });
    }
  }, [arFetched, defaultValues, reset]);

  const isARDirty = useMemo(
    () => Object.values(dirtyTabs).some((tabIsDirty) => tabIsDirty) || isARDetailsDirty,
    [dirtyTabs, isARDetailsDirty]
  );

  const ownedTabs = useMemo(() => {
    const DEFAULT_OWNED_TABS = ["details", "attachments", "collaboration", "history"];
    if (arDisciplinesFetched) {
      return approvalRequestDisciplines.reduce((tabs: string[], discipline) => {
        if ([discipline.primaryApprover, discipline.secondaryApprover].includes(userId ?? "") || isAdmin) {
          tabs.push(discipline.id);
        }
        return tabs;
      }, DEFAULT_OWNED_TABS);
    }

    return DEFAULT_OWNED_TABS;
  }, [approvalRequestDisciplines, arDisciplinesFetched, isAdmin, userId]);

  const userIsSMEOfDiscipline = useMemo(
    () => !!approvalRequestDisciplineId && ownedTabs.includes(approvalRequestDisciplineId) && isSME,
    [approvalRequestDisciplineId, isSME, ownedTabs]
  );

  const userIsSMEOfDisciplineOrAdmin = useMemo(
    () => userIsSMEOfDiscipline || isAdmin,
    [isAdmin, userIsSMEOfDiscipline]
  );

  const userIsSMEOfAr = useMemo(() => {
    return approvalRequestDisciplines.some((x) => ownedTabs.includes(x.id)) && isSME;
  }, [approvalRequestDisciplines, isSME, ownedTabs]);

  const appendAlertBanner = useMemo(
    () => (alertBannerConfig: AlertBannerConfig) => {
      setAlertBanners((old) => {
        return [...filteredAlertBanner(old, [alertBannerConfig.id]), alertBannerConfig];
      });
    },
    []
  );

  const removeAlertBanner = (ids: string[]) => {
    setAlertBanners((old) => filteredAlertBanner(old, ids));
  };

  const resetAlertBanner = (exemptions: AlertBannerConfig["id"][]) => {
    if (exemptions) {
      setAlertBanners((old) => old.filter(({ id }) => exemptions.includes(id)));
    } else {
      setAlertBanners([]);
    }
  };

  const filteredAlertBanner = (existingList: AlertBannerConfig[], ids: string[]) =>
    existingList.filter((entry) => !ids.includes(entry.id));

  const saveApprovalRequest = useCallback(
    (
      request: UpdateApprovalRequest,
      onSuccess?: (updatedAR: ApprovalRequestBase) => void,
      onError?: (error: APIException) => void
    ) => {
      return updateApprovalRequestHook(request, {
        onSuccess: onSuccess,
        onError: (error) => {
          onError?.(error as APIException);
        }
      });
    },
    [updateApprovalRequestHook]
  );

  const deleteApprovalRequest = useCallback(
    (onError?: () => void) => {
      deleteApprovalRequestHook(approvalRequestId!, {
        onSuccess: () => {
          navigate(PATH.MY_REQUESTS, { replace: true });
        },
        onError: onError
      });
    },
    [deleteApprovalRequestHook, approvalRequestId, navigate]
  );

  const updateAllTrackingComments = (
    updatedTabs: Record<string, DisciplineTrackingComment[]>,
    isDirty: boolean = true
  ) => {
    setAllTrackingComments((old) => ({
      ...old,
      ...updatedTabs
    }));

    const updatedDirtyStates: Record<string, boolean> = {};
    Object.keys(updatedTabs).forEach((tab) => (updatedDirtyStates[tab] = isDirty));

    setDirtyTabs((old) => ({
      ...old,
      ...updatedDirtyStates
    }));
  };

  const updateAllAuthorisationComments = (
    updatedTabs: Record<string, DisciplineAuthorisationComment[]>,
    isDirty: boolean = true
  ) => {
    setAllAuthorisationComments((old) => ({
      ...old,
      ...updatedTabs
    }));

    const updatedDirtyStates: Record<string, boolean> = {};
    Object.keys(updatedTabs).forEach((tab) => (updatedDirtyStates[tab] = isDirty));

    setDirtyTabs((old) => ({
      ...old,
      ...updatedDirtyStates
    }));
  };

  const resetDisciplineTab = (approvalRequestDisciplineId: string) => {
    setAllTrackingComments((old) => {
      delete old[approvalRequestDisciplineId];
      return old;
    });
    setAllAuthorisationComments((old) => {
      delete old[approvalRequestDisciplineId];
      return old;
    });
    setDirtyTabs((old) => {
      delete old[approvalRequestDisciplineId];
      return old;
    });
    setAllTrackingCommentErrors((old) => {
      delete old[approvalRequestDisciplineId];
      return old;
    });
  };

  const { mutate: updateDisciplineComments } = useUpdateDisciplineComments(
    approvalRequestId!,
    isAuthorised,
    () => {
      const updatedTrackingTabs: Record<string, DisciplineTrackingComment[]> = {};
      const updatedAuthorisationTabs: Record<string, DisciplineAuthorisationComment[]> = {};

      updatedTrackingTabs[approvalRequestDisciplineId!] = allTrackingComments[approvalRequestDisciplineId!];
      updatedAuthorisationTabs[approvalRequestDisciplineId!] = allAuthorisationComments[approvalRequestDisciplineId!];

      updateAllTrackingComments(updatedTrackingTabs, false);
      updateAllAuthorisationComments(updatedAuthorisationTabs, false);

      if (isAuthorised) {
        authoriseDiscipline({
          approvalRequestDisciplineId,
          approvalRequestId: approvalRequestId
        });
      }

      setSnackBar({
        message: DISCIPLINE_MESSAGE.SAVE_SUCCESSFUL,
        open: true
      });
    },
    () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  );

  const { mutate: authoriseDiscipline } = useAuthoriseDiscipline(
    approvalRequestId!,
    () => {
      setIsAuthorised(false);
      setSnackBar({
        message: DISCIPLINE_MESSAGE.SAVE_SUCCESSFUL,
        open: true
      });
    },
    () => {
      appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
    }
  );

  const authoriseDisciplineHandler = () => {
    setIsAuthorised(true);
    if (isTrackingCommentUpdatable || isAuthorisationCommentUpdatable) {
      saveDisciplineTabs();
    } else {
      authoriseDiscipline({
        approvalRequestDisciplineId,
        approvalRequestId: approvalRequestId
      });
    }
  };

  const saveDisciplineTabs = useCallback(
    (onSuccess?: () => void, onFail?: () => void) => {
      let disciplineTrackingComments: DisciplineTrackingComment[] = [];
      let disciplineAuthorisationComments: DisciplineAuthorisationComment[] = [];

      const tabsToUpdate: Record<string, DisciplineTrackingComment[]> = Object.entries(dirtyTabs).reduce(
        (commentsToUpdate: Record<string, DisciplineTrackingComment[]>, [approvalRequestDisciplineId, tabIsDirty]) => {
          if (tabIsDirty && ownedTabs.includes(approvalRequestDisciplineId)) {
            commentsToUpdate[approvalRequestDisciplineId] = allTrackingComments[approvalRequestDisciplineId];
            disciplineTrackingComments = commentsToUpdate[approvalRequestDisciplineId];
          }
          return commentsToUpdate;
        },
        {}
      );

      const authTabsToUpdate: Record<string, DisciplineAuthorisationComment[]> = Object.entries(dirtyTabs).reduce(
        (
          authCommentsToUpdate: Record<string, DisciplineAuthorisationComment[]>,
          [approvalRequestDisciplineId, tabIsDirty]
        ) => {
          if (tabIsDirty && ownedTabs.includes(approvalRequestDisciplineId)) {
            authCommentsToUpdate[approvalRequestDisciplineId] = allAuthorisationComments[approvalRequestDisciplineId];
          }
          return authCommentsToUpdate;
        },
        {}
      );

      disciplineAuthorisationComments = authTabsToUpdate[approvalRequestDisciplineId!] ?? [];

      const validateComments = () => {
        Object.entries(tabsToUpdate).forEach(([approvalRequestDisciplineId, trackingComments]) => {
          trackingCommentsSchema
            .validate(trackingComments, { abortEarly: false })
            .then(() => {
              setAllTrackingCommentErrors((existingErrors) => {
                const updatedErrors = { ...existingErrors };
                delete updatedErrors[approvalRequestDisciplineId];
                return updatedErrors;
              });
              updateDisciplineComments(
                {
                  approvalRequestDisciplineId,
                  disciplineTrackingComments,
                  disciplineAuthorisationComments
                },
                {
                  onSuccess
                }
              );
            })
            .catch(({ inner }: ValidationError) => {
              const tcErrors: DisciplineTrackingCommentError[] = inner.map((error) => ({
                trackingCommentId: error.params?.["trackingCommentId"] as string,
                expectedAuthorisationDate: error.path === "expectedAuthorisationDate" ? error.message : undefined,
                disciplineTrackingCommentDependencies:
                  error.path === "disciplineTrackingCommentDependencies" ? error.message : undefined
              }));
              setAllTrackingCommentErrors((existingErrors) => ({
                ...existingErrors,
                [approvalRequestDisciplineId]: tcErrors
              }));
              onFail?.();
              return;
            });
        });
      };

      if (isTrackingCommentUpdatable || isAuthorisationCommentUpdatable) {
        validateComments();
      }
    },
    [
      dirtyTabs,
      approvalRequestDisciplineId,
      isTrackingCommentUpdatable,
      isAuthorisationCommentUpdatable,
      ownedTabs,
      allTrackingComments,
      allAuthorisationComments,
      setAllTrackingCommentErrors,
      updateDisciplineComments
    ]
  );

  if (!approvalRequestId) {
    console.error("Unable to find `approvalRequestId` in URL path params.");
    navigate(PATH.MY_REQUESTS);
    return null;
  }

  if ([arFetched, arDisciplinesFetched, arTypesFetched].some((fetched) => !fetched)) {
    return <Loading prefix="Approval Request" />;
  }

  // Values are asserted as not null, since the "fetched" checks above ensures they have something.
  const contextValue: ApprovalRequestContextType = {
    approvalRequestId,
    approvalRequestDisciplineId,
    approvalRequest: approvalRequest!,
    approvalRequestType: approvalRequestTypes.find((arType) => arType.id === approvalRequest?.approvalRequestTypeId)!,
    isARDirty,
    saveApprovalRequest,
    deleteApprovalRequest,
    alertBanners,
    appendAlertBanner,
    removeAlertBanner,
    resetAlertBanner,
    dirtyTabs,
    ownedTabs,
    approvalRequestDisciplines,
    allTrackingComments,
    updateAllTrackingComments,
    allTrackingCommentErrors,
    updateAllTrackingCommentErrors: setAllTrackingCommentErrors,
    allAuthorisationComments,
    updateAllAuthorisationComments,
    resetDisciplineTab,
    attachmentCountIndicator,
    setAttachmentCountIndicator,
    userIsSMEOfDiscipline,
    userIsSMEOfDisciplineOrAdmin,
    saveDisciplineTabs,
    authoriseDisciplineHandler,
    userIsSMEOfAr
  };

  return (
    <Suspense fallback={<Loading prefix="Approval Request" />}>
      <ApprovalRequestContext.Provider value={contextValue}>
        <FormProvider {...formMethods}>{children}</FormProvider>
      </ApprovalRequestContext.Provider>
    </Suspense>
  );
}
